<template>
  <div>
    <div class="title-section">
      <SfHeading
        :level="4"
        :title="$t(title)"
        class="title sf-heading"
      />
      <span v-if="categories.length" class="counter">({{ countItems }})</span>
      <span v-else-if="visibleProducts.length" class="counter">({{ countItems }})</span>
    </div>
    <div class="suggestions" v-if="suggestions.length">
      <ul class="search-items">
        <li
          v-for="(suggestion, index) in suggestions"
          v-html="suggestion"
          :key="`suggestion-${index}`"
          class="search-item orange-part search-item--suggestion"
          data-transaction-name="Search - Suggest"
          @click="setSearch(suggestion)"
        />
      </ul>
    </div>
    <div v-else-if="categories.length">
      <ul class="search-items">
        <router-link
          v-for="category in categories"
          :key="category.category_id"
          class="orange-part"
          :to="localizedRoute(`/${category.url_path}`)"
        >
          <li
            data-transaction-name="Search - Close"
            @click="closePanel"
            class="search-item"
            v-html="getHighlightedText(category.name)"
          />
        </router-link>
      </ul>
    </div>
    <div v-else-if="text" class="no-result-text">
      {{ text }}
    </div>
    <div v-else-if="!searchValue.length">
      <ul class="search-items">
        <li
          data-transaction-name="Search - All Results"
          @click="seeAll(item)"
          class="search-item"
          v-for="(item, i) in getSearchHistory"
          :key="i"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="product-cards" v-else-if="visibleProducts.length">
      <SfOProductCard
        v-for="product in visibleProducts"
        :key="product.id"
        type="row"
        class="search-product-card"
        :image="product.image || product.src"
        :title="getHighlightedText(product.title)"
        :regular-price="product.price.regular"
        :special-price="product.price.special"
        :link="product.link"
        :is-new-post="isNewPost"
        :product="product"
        wish-list-icon="heart"
        link-tag="router-link"
        :product-quantity="product.quantityText"
        @linkClicked="linkClicked"
      />
    </div>
  </div>
</template>

<script>
import SfOProductCard from 'theme/components/storefront-override/SfOProductCard';
import { SfHeading } from '@storefront-ui/vue';
import { mapActions, mapGetters } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'MSearchPanelBlock',
  components: {
    SfHeading,
    SfOProductCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    categories: {
      type: [Object, Array],
      default: () => ({})
    },
    visibleProducts: {
      type: [Object, Array],
      default: () => ({})
    },
    suggestions: {
      type: [Object, Array],
      default: () => ({})
    },
    goodsCount: {
      type: Number,
      default: 0
    },
    searchValue: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      getSearchHistory: 'userInfo/getSearchHistory',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    countItems () {
      return this.categories.length ? this.categories.length : this.goodsCount
    }
  },
  methods: {
    ...mapActions({
      setSearchResult: 'search-result/loadSearch',
      saveSearchHistory: 'userInfo/saveSearchHistory'
    }),
    linkClicked () {
      this.closePanel()
    },
    getHighlightedText (inputString) {
      let substrIndex = (`${inputString}`).toLowerCase().indexOf(this.searchValue.toLowerCase())

      if (substrIndex !== -1) {
        let substring = inputString.substring(substrIndex, substrIndex + this.searchValue.length)

        return `${inputString.substring(0, substrIndex)}<span style="color: #EB6747">${substring}</span>${inputString.substring(substrIndex + this.searchValue.length)}`
      }

      return inputString
    },
    closePanel () {
      setTimeout(() => {
        this.$store.commit('ui/setSearchpanel', false)
      }, 0)
    },
    seeAll (search) {
      this.$store.commit('ui/setSearchpanel', false)
      this.$router.push(localizedRoute({ name: 'search', query: { q: search } }));
      this.setSearchResult({
        route: this.$route,
        search,
        page: 1,
        size: 40
      });
    },
    stripHtml (html) {
      const element = document.createElement('div');
      element.innerHTML = html;
      return element.textContent || element.innerText || '';
    },
    setSearch (suggestion) {
      this.$emit('set-search', this.stripHtml(suggestion))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~theme/css/breakpoints";

::v-deep {
  .sf-heading {
    display: inline-block;
    padding: 0;
  }

  .sf-heading__title {
    font-size: var(--font-size-14);
    font-weight: var(--font-medium);
    line-height: 17px;
    color: var(--black);
  }
}

.title-section {
  padding: 0 0 15px;
  border-bottom: 1px solid var(--line-gray);
  margin-bottom: 15px;
}

.search-items {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--black);
}

.search-item {
  font-size: var(--font-size-13);
  line-height: var(--spacer-16);
  margin-bottom: var(--spacer-10);
  cursor: pointer;

  &--suggestion {
    color: var(--orange);

    ::v-deep b {
      font-weight: normal;
      color: var(--black)
    }
  }
}

.counter {
  font-size: var(--font-size-14);
}

.search-product-card {
  margin-bottom: var(--spacer-10);
}
</style>

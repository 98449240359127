<template>
  <div
    class="o-search-panel"
    :class="{'o-search-panel--banner': showPreHeaderBanner}"
  >
    <div class="container">
      <ALoadingSpinner
        key="spinner"
        v-show="loading"
        :size="24"
        :weight="3"
        :is-absolute-position="true"
      />
      <div v-if="!search.length">
        <MSearchPanelBlock
          v-if="getSearchHistory.length > 0"
          class="history-block search-panel-block"
          title="Search history"
        />
      </div>
      <div v-else>
        <div
          v-if="getCorrection"
          class="correction-search"
        >
          {{ $t('Displayed results by request:') }}
          <i @click="setSearchCorrection">{{ getCorrection }}</i>
        </div>

        <MSearchPanelBlock
          v-if="!!getSuggestion.length"
          class="popular-search search-panel-block"
          title="Often searched"
          :suggestions="getSuggestion"
          @set-search="setSearch"
        />

        <MSearchPanelBlock
          v-if="!!search.length && !!visibleProducts.length"
          class="goods-block search-panel-block"
          :search-value="search"
          :goods-count="resultCount"
          :visible-products="visibleProducts"
          title="Goods"
        />

        <MSearchPanelBlock
          v-if="!!getCategories.length"
          class="category-block search-panel-block"
          :search-value="search"
          :categories="getCategories"
          title="Categories"
        />
      </div>

      <div v-if="showNoResults">
        <p
          class="no-result-text"
          :class="{ 'no-result-text--loading': loading }"
        >
          {{ $t('No results were found for your search. Refine your request') }}
        </p>
      </div>

      <div v-if="search.length && visibleProducts.length" class="products">
        <SfButton
          class="sf-button--pure"
          type="button"
          data-transaction-name="All Search Results"
          @click="$emit('see-all')"
        >
          {{ $t("All search results") }} ({{ resultCount }})
        </SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import VueOfflineMixin from 'vue-offline/mixin';
import { SfButton } from '@storefront-ui/vue';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { mapGetters, mapState } from 'vuex';
import MSearchPanelBlock from 'theme/components/molecules/m-search-panel-block';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';

export default {
  name: 'OSearchPanel',
  components: {
    SfButton,
    ALoadingSpinner,
    MSearchPanelBlock
  },
  mixins: [VueOfflineMixin],
  data () {
    return {
      selectedCategoryIds: []
    };
  },
  props: {
    search: {
      type: String,
      required: true,
      default: ''
    },
    searchHistory: {
      type: [Object, Array],
      default: () => ({})
    },
    pageSize: {
      type: Number,
      required: true,
      default: 16
    }
  },
  computed: {
    ...mapGetters({
      getSearchHistory: 'userInfo/getSearchHistory',
      getSearchResult: 'search-result/getSearchResult',
      loading: 'search-result/getLoadingAutocomplete'
    }),
    ...mapState({
      showPreHeaderBanner: state => state.ui.preHeaderBanner
    }),
    getSuggestion () {
      return this.getSearchResult.results.suggest
    },
    getCorrection () {
      return this.getSearchResult.corrected?.phrase
    },
    visibleProducts () {
      return this.getSearchResult.results.items
    },
    getCategories () {
      return this.getSearchResult.results.categories
    },
    resultCount () {
      return this.getSearchResult.total
    },
    showNoResults () {
      return this.search.length &&
        !this.getSuggestion.length &&
        !this.visibleProducts.length &&
        !this.getCategories.length
    }
  },
  watch: {
    getCategories () {
      this.selectedCategoryIds = [];
    }
  },
  mounted () {
    disableBodyScroll(this.$el)
  },
  destroyed () {
    clearAllBodyScrollLocks()
  },
  methods: {
    setSearch (suggestion) {
      this.$emit('set-search', suggestion)
    },
    setSearchCorrection () {
      if (!this.getCorrection) {
        return
      }

      this.$emit('set-search-correction', this.getCorrection)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.o-search-panel {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--c-white);
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 8.125rem);

  @include for-desktop {
    &--banner {
      max-height: calc(100vh - 8.125rem - var(--pre-header-desktop-height, 0));
    }
  }

  @include for-mobile {
    position: fixed;
    top: auto;
    height: 100vh !important;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--light-orange);

    &:hover {
      background-color: var(--orange);
    }
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: var(--orange) transparent;
  }

  .no-result-text {
    font-size: var(--font-size-13);
    margin: 0;

    &--loading {
      opacity: 0;
    }
  }

  .container {
    padding: 20px var(--spacer-sm);
    max-width: 1272px;
    margin: auto;
    position: relative;
    @include for-desktop {
      border-top: 1px solid var(--c-light);
    }
    @include for-mobile {
      padding: 0 var(--spacer-xl);
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

  }

  .products {
    width: 100%;
    padding-top: var(--spacer-15);
    border-top: 1px solid var(--line-gray);
  }
}

.popular-search  ::v-deep .search-item:last-child {
  margin-bottom: 0;
}

.correction-search {
  margin-bottom: var(--spacer-20);
  font-size: var(--font-size-14);
  font-weight: var(--font-medium);
  line-height: 17px;
  color: var(--black);

  i {
    cursor: pointer;
    color: var(--orange);
  }
}

.history-block  ::v-deep .search-item:last-child {
  margin-bottom: var(--spacer-30);
}

.search-panel-block {
  margin-bottom: var(--spacer-30);

  &:last-child {
    margin-bottom: 0;
  }
  ::v-deep {
    .varus-sale-product {
      height: px2rem(30);

      @include for-desktop {
        height: px2rem(25);
      }

      &__logo {
        width: px2rem(30);
        height: px2rem(30);

        @include for-desktop {
          width: px2rem(25);
          height: px2rem(25);
        }

        svg {
          width: px2rem(15);

          @include for-desktop {
            width: px2rem(10);
          }
        }
      }

      &__text {
        font-size: var(--font-size-12);

        span {
          font-size: var(--font-size-12);
          margin-right: var(--spacer-4);
        }
      }
    }
  }
}

.sf-button--pure {
  font-size: var(--font-size-14);
  color: var(--orange);
  font-weight: var(--font-base);
}
</style>
